import React, { useRef, useState } from 'react';
import { Button, Group, Select, Stack, Textarea } from '@mantine/core';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  variables: string[];
}

const InsertVariableTextarea: React.FC<Props> = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  variables,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [selectedVariable, setSelectedVariable] = useState<string | null>(null);

  const insertVariable = () => {
    if (!selectedVariable || !textareaRef.current) {
      return;
    }

    const el = textareaRef.current;
    const start = el.selectionStart ?? value.length;
    const end = el.selectionEnd ?? value.length;
    console.log('Start:', start);
    console.log('End:', end);
    console.log('Before:', value.slice(0, start));
    console.log('After:', value.slice(end));

    const newValue = value.slice(0, start) + selectedVariable + value.slice(end);
    onChange(newValue);

    // Set cursor right after inserted variable
    const cursor = start + selectedVariable.length;
    setTimeout(() => {
      el.focus();
      el.selectionStart = cursor;
      el.selectionEnd = cursor;
    }, 0);

    setSelectedVariable(null);
  };

  return (
    <Stack>
      <Group align="flex-end">
        <Select
          label="Insert Value"
          placeholder="Choose a Value"
          searchable
          clearable
          data={variables.map((v) => ({ label: v, value: v }))}
          value={selectedVariable}
          onChange={setSelectedVariable}
        />
        <Button disabled={!selectedVariable} onClick={insertVariable}>
          Insert
        </Button>
      </Group>

      <Textarea
        name={name}
        ref={textareaRef}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        autosize
        minRows={8}
        maxRows={20}
        required
      />
    </Stack>
  );
};

export default InsertVariableTextarea;
