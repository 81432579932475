import React, { useEffect, useRef, useState } from 'react';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { IconCheck, IconX } from '@tabler/icons-react';
import { Button, Group, Loader, Paper, Stack, Text, TextInput, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  fetchBillingData,
  fetchStripeSession,
  fetchStripeSessionStatus,
} from '@/services/subaccountService';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || '');

const SubAccountDashboardPage: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [_sessionId, setSessionId] = useState<string | null>(null);
  const [status, setStatus] = useState<'loading' | 'error' | 'success' | 'idle'>('idle');
  const [hasBilling, setHasBilling] = useState<boolean>(false);
  const [billingLoaded, setBillingLoaded] = useState<boolean>(false);

  const pollingRef = useRef<NodeJS.Timeout | null>(null);

  const _startPollingStatus = (sessionId: string) => {
    pollingRef.current = setInterval(async () => {
      try {
        const response = await fetchStripeSessionStatus(sessionId);
        if (response?.data?.status === 'complete') {
          clearInterval(pollingRef.current!);
          setStatus('success');
          notifications.show({
            title: 'Payment Successful',
            message: 'Your payment has been completed successfully.',
            color: 'green',
            icon: <IconCheck />,
          });
        }
      } catch (_err) {
        clearInterval(pollingRef.current!);
        setStatus('error');
        notifications.show({
          title: 'Session Polling Failed',
          message: 'Unable to verify payment status. Please try again.',
          color: 'red',
          icon: <IconX />,
        });
      }
    }, 5000);
  };

  const startCheckout = async () => {
    try {
      if (!email) {
        notifications.show({
          title: 'Missing Email',
          message: 'Please enter your email before proceeding.',
          color: 'red',
          icon: <IconX />,
        });
        return;
      }

      setStatus('loading');
      const session = await fetchStripeSession(email);
      console.log('startCheckout -> session', session);
      if (!session?.data?.sessionId) {
        throw new Error('Missing sessionId in Stripe session response');
      }

      const clientSecret = session.data.sessionId;
      setSessionId(clientSecret);
      setClientSecret(clientSecret);
      setStatus('idle');
      // startPollingStatus(clientSecret);
    } catch (error) {
      console.error('Failed to start checkout:', error);
      setStatus('error');
      notifications.show({
        title: 'Checkout Error',
        message: 'Unable to create a Stripe checkout session.',
        color: 'red',
        icon: <IconX />,
      });
    }
  };

  useEffect(() => {
    const loadBilling = async () => {
      try {
        const billing = await fetchBillingData();
        // if billing.account_balance > 0, client has registered
        if (billing && billing.account_balance > 0) {
          setHasBilling(true);
        }
      } catch (_error) {
        console.warn('No billing data found or failed to load.');
      } finally {
        setBillingLoaded(true);
      }
    };

    loadBilling();

    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, []);

  if (status === 'success') {
    return (
      <Stack p="lg">
        <Title order={4} c="green">
          🎉 Payment successful! Thank you.
        </Title>
      </Stack>
    );
  }

  if (!billingLoaded) {
    return (
      <Stack p="lg">
        <Loader variant="dots" />
      </Stack>
    );
  }

  return (
    <Paper
      withBorder
      shadow="sm"
      radius="md"
      style={{ width: '100%', minWidth: '100%', overflow: 'auto' }}
    >
      <Stack p="lg" gap="md" align="stretch" w="100%" maw={600}>
        <Title order={3}>Dashboard</Title>

        {status !== 'idle' && status !== 'error' && <Loader variant="dots" />}

        {!clientSecret && !hasBilling && billingLoaded && (
          <>
            <TextInput
              label="Email Address"
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              required
            />
            <Button onClick={startCheckout} disabled={status === 'loading'}>
              Start Registration
            </Button>
          </>
        )}

        {hasBilling && (
          <>
            <Group>
              <Text>Registration :</Text>
              <Text c="teal.4">Active</Text>
            </Group>
          </>
        )}

        {clientSecret && stripePromise && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret,
              onComplete: () => {
                setStatus('success');
                notifications.show({
                  title: 'Payment Successful',
                  message: 'Your payment has been completed successfully.',
                  color: 'green',
                  icon: <IconCheck />,
                });
              },
            }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}

        {status === 'error' && (
          <Button variant="subtle" color="blue" onClick={startCheckout}>
            Try again
          </Button>
        )}
      </Stack>
    </Paper>
  );
};

export default SubAccountDashboardPage;
