// /src/components/OrderCard.tsx
import React from 'react';
import { IconCalendarTime, IconInfoCircle } from '@tabler/icons-react';
import { ActionIcon, Badge, Card, Group, Image, Modal, Stack, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { OrderViewModel } from '@/interfaces/Orders';

interface OrderCardProps {
  order: OrderViewModel;
}

const OrderCard: React.FC<OrderCardProps> = ({ order }) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Card shadow="sm" padding="md" radius="md" withBorder style={{ maxWidth: 320 }}>
        <Card.Section withBorder inheritPadding py="xs">
          <Group justify="space-between">
            <Stack gap="xs">
              <Text size="sm" c="dimmed">
                Product ID: {order.productId}
              </Text>
              <Badge size="xs" color={order.status === 'completed' ? 'green' : 'blue'}>
                {order.status.toUpperCase()}
              </Badge>
              {order.isTestMode && (
                <Badge size="xs" color="yellow" variant="outline">
                  Test Mode
                </Badge>
              )}
            </Stack>
            <Tooltip label="View image" withArrow>
              <ActionIcon onClick={open}>
                <IconInfoCircle size={18} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Card.Section>

        <Card.Section mt="xs">
          <Image
            src={order.frontImageUrl || 'https://placehold.co/400x300'}
            alt="Postcard front"
            height={160}
            fit="contain"
            style={{ cursor: 'pointer' }}
            onClick={open}
          />
        </Card.Section>

        <Stack gap="xs" mt="sm">
          <Group gap="xs">
            <IconCalendarTime size={16} />
            <Text size="sm">{new Date(order.createdAt).toLocaleString()}</Text>
          </Group>
          <Text size="sm" c="dimmed">
            Size: {order.size}
          </Text>
          <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
            {order.message}
          </Text>
        </Stack>
      </Card>

      <Modal opened={opened} onClose={close} padding={0} centered size="lg">
        <Image src={order.frontImageUrl} alt="Postcard front full" width="100%" />
      </Modal>
    </>
  );
};

export default OrderCard;
