// src/pages/PrivacyPolicyPage.tsx
import React from 'react';
import { Container, Divider, List, Paper, Stack, Text, Title } from '@mantine/core';

const PrivacyPolicyPage = () => {
  return (
    <Container size="md" py="xl">
      <Title order={1} ta="center" mb="xl">
        Privacy Policy
      </Title>

      <Paper shadow="xs" radius="md" p="lg" withBorder>
        <Stack gap="lg">
          <Text size="sm" c="dimmed">
            <strong>Effective Date:</strong> March 31, 2025
          </Text>

          <Text>
            This Privacy Policy explains how <strong>RunIt Postcards</strong> ("we", "our", or "us")
            collects, uses, and protects your information when you use our application and related
            services (the "Service").
          </Text>
          <Text>
            By accessing or using the Service, you agree to the collection and use of information in
            accordance with this policy.
          </Text>

          <Divider label="1. Information We Collect" labelPosition="left" />

          <Title order={4}>a. Account & CRM Data</Title>
          <List spacing="xs" withPadding>
            <List.Item>Agency and sub-account IDs</List.Item>
            <List.Item>Sub-account names and locations</List.Item>
            <List.Item>CRM contact information (name, email, address, etc.)</List.Item>
            <List.Item>Tags or custom fields relevant to mail automation</List.Item>
          </List>

          <Title order={4}>b. Billing Information</Title>
          <List spacing="xs" withPadding>
            <List.Item>Account balance settings (minimum, maximum)</List.Item>
            <List.Item>External billing provider ID (e.g., customer ID)</List.Item>
            <List.Item>Transaction history, including top-ups and order charges</List.Item>
          </List>
          <Text>
            We <strong>do not store full payment card details</strong>. All payment processing is
            handled securely through a third-party payment provider.
          </Text>

          <Title order={4}>c. Order Details</Title>
          <List spacing="xs" withPadding>
            <List.Item>Recipient names and addresses</List.Item>
            <List.Item>Message content and product details</List.Item>
            <List.Item>Sender information (name, address)</List.Item>
            <List.Item>Image assets you upload</List.Item>
            <List.Item>Handwriting styles and personalization metadata</List.Item>
          </List>

          <Title order={4}>d. Technical Data</Title>
          <List spacing="xs" withPadding>
            <List.Item>IP address</List.Item>
            <List.Item>Browser or device type</List.Item>
            <List.Item>Usage data (e.g., API requests, error logs)</List.Item>
          </List>

          <Divider label="2. How We Use Your Information" labelPosition="left" />
          <List spacing="xs" withPadding>
            <List.Item>Authenticate and authorize users via SSO</List.Item>
            <List.Item>Configure billing and process payments</List.Item>
            <List.Item>Fulfill direct mail orders through third-party providers</List.Item>
            <List.Item>Enable product customization and asset uploads</List.Item>
            <List.Item>Monitor platform usage, maintain security, and improve features</List.Item>
            <List.Item>Provide customer support</List.Item>
          </List>

          <Divider label="3. Sharing of Information" labelPosition="left" />
          <List spacing="xs" withPadding>
            <List.Item>
              <strong>Third-party payment processors</strong> for billing transactions
            </List.Item>
            <List.Item>
              <strong>Fulfillment providers</strong> to execute your mail orders
            </List.Item>
            <List.Item>
              <strong>CRM APIs</strong> to fetch or sync contact and custom field data
            </List.Item>
            <List.Item>
              <strong>Legal authorities</strong>, if required by law or court order
            </List.Item>
          </List>
          <Text>
            We do <strong>not</strong> sell your personal data to any third party.
          </Text>

          <Divider label="4. Data Storage & Security" labelPosition="left" />
          <Text>
            Your data is stored securely on our cloud infrastructure, with appropriate access
            controls, encryption, and audit logs. We use industry-standard safeguards to protect
            your information from unauthorized access, disclosure, or modification.
          </Text>

          <Divider label="5. Data Retention" labelPosition="left" />
          <Text>We retain your information only for as long as necessary to:</Text>
          <List spacing="xs" withPadding>
            <List.Item>Provide our services</List.Item>
            <List.Item>Comply with legal obligations</List.Item>
            <List.Item>Maintain accurate billing and audit records</List.Item>
          </List>
          <Text>
            You may request deletion of your account or associated data by contacting our support
            team.
          </Text>

          <Divider label="6. Your Rights" labelPosition="left" />
          <List spacing="xs" withPadding>
            <List.Item>Access the personal data we hold about you</List.Item>
            <List.Item>Request correction or deletion of your data</List.Item>
            <List.Item>Withdraw consent to data processing (if applicable)</List.Item>
          </List>
          <Text>To exercise these rights, contact us using the email below.</Text>

          <Divider label="7. Children's Privacy" labelPosition="left" />
          <Text>
            Our service is not intended for users under the age of 18. We do not knowingly collect
            information from minors.
          </Text>

          <Divider label="8. Changes to This Policy" labelPosition="left" />
          <Text>
            We may update this Privacy Policy at any time. We will notify you of significant changes
            via email or in-app notification.
          </Text>

          <Divider label="9. Contact Us" labelPosition="left" />
          <Text>If you have questions about this Privacy Policy, please contact us at:</Text>
          <Text mt="xs" size="sm">
            <strong>Email:</strong> support@runitpostcards.com
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicyPage;
