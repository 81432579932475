// src/pages/TermsPage.tsx
import React from 'react';
import { Container, Divider, List, Paper, Stack, Text, Title } from '@mantine/core';

const TermsPage = () => {
  return (
    <Container size="md" py="xl">
      <Title order={1} ta="center" mb="xl">
        Terms and Conditions
      </Title>

      <Paper shadow="xs" radius="md" p="lg" withBorder>
        <Stack gap="lg">
          <Text size="sm" c="dimmed">
            <strong>Effective Date:</strong> March 31, 2025
          </Text>

          <Text>
            Welcome to <strong>RunIt Postcards</strong>, a software application that enables users
            to manage and send direct mail marketing campaigns. By accessing or using our services,
            you agree to be bound by these Terms and Conditions ("Terms").
          </Text>

          <Divider label="1. Eligibility" labelPosition="left" />
          <Text>
            You must be a registered user of a supported CRM platform and have authorized RunIt
            Postcards to access your account. You must be at least 18 years old to use this service.
          </Text>

          <Divider label="2. Services Provided" labelPosition="left" />
          <List spacing="xs" withPadding>
            <List.Item>Design and manage direct mail products</List.Item>
            <List.Item>Automate delivery of mail campaigns through third-party providers</List.Item>
            <List.Item>Configure billing and manage account balances</List.Item>
            <List.Item>Manage sub-account and agency-level settings</List.Item>
          </List>

          <Divider label="3. Billing and Payments" labelPosition="left" />
          <Text>
            Each sub-account operates on a pre-funded balance model. When your account balance falls
            below your configured threshold, an automatic payment will be initiated using your
            stored billing method through a third-party payment provider.
          </Text>
          <Text>
            You are responsible for maintaining an active and valid payment method at all times. If
            a payment fails, your services may be paused or restricted until payment is completed.
          </Text>

          <Divider label="4. Order Fulfillment" labelPosition="left" />
          <Text>
            Orders placed through RunIt Postcards are fulfilled by third-party providers. Once
            submitted, orders are considered final unless an error occurs during processing. We do
            not guarantee the timing, delivery, or accuracy of fulfillment performed by third
            parties.
          </Text>

          <Divider label="5. Refunds" labelPosition="left" />
          <Text>
            If an order fails before it is accepted by a fulfillment provider, a refund may be
            credited to your account balance. Refunds for completed orders are not guaranteed and
            will be reviewed on a case-by-case basis.
          </Text>

          <Divider label="6. Content and Uploads" labelPosition="left" />
          <Text>
            You are responsible for all content uploaded to the platform. Images must meet our size,
            format, and content guidelines. You may not upload copyrighted, illegal, misleading, or
            offensive material.
          </Text>
          <Text>
            We reserve the right to reject or remove content that violates these guidelines or
            applicable laws.
          </Text>

          <Divider label="7. Acceptable Use" labelPosition="left" />
          <List spacing="xs" withPadding>
            <List.Item>Misuse or attempt to bypass system protections</List.Item>
            <List.Item>Use the platform for unsolicited or harmful communications</List.Item>
            <List.Item>Abuse integrations or API usage</List.Item>
            <List.Item>Impersonate another person or misrepresent your identity</List.Item>
          </List>

          <Divider label="8. Third-Party Services" labelPosition="left" />
          <Text>
            We rely on third-party providers for services such as billing, order fulfillment, and
            delivery logistics. We do not control and are not responsible for their availability,
            performance, or errors resulting from their systems.
          </Text>

          <Divider label="9. Termination" labelPosition="left" />
          <Text>
            We may suspend or terminate your access to the platform at any time for violations of
            these Terms, suspected fraud, non-payment, or abuse of the system.
          </Text>

          <Divider label="10. Limitation of Liability" labelPosition="left" />
          <Text>
            RunIt Postcards is provided “as is” without warranties of any kind. We are not liable
            for any direct, indirect, or incidental damages resulting from the use of the service,
            including but not limited to:
          </Text>
          <List spacing="xs" withPadding>
            <List.Item>Delivery failures</List.Item>
            <List.Item>Billing errors</List.Item>
            <List.Item>Platform outages</List.Item>
            <List.Item>Third-party service interruptions</List.Item>
          </List>

          <Divider label="11. Changes to These Terms" labelPosition="left" />
          <Text>
            We may update these Terms from time to time. You will be notified of significant changes
            via email or in-app notification. Continued use of the platform after updates
            constitutes acceptance of the revised Terms.
          </Text>

          <Divider label="12. Governing Law" labelPosition="left" />
          <Text>
            These Terms are governed by the laws of the State of Oklahoma, United States, without
            regard to conflict of law principles.
          </Text>

          <Divider />

          <Text size="sm" mt="md">
            <strong>Contact Us</strong>
            <br />
            For questions or support regarding these Terms, please contact our support team at{' '}
            <Text component="span" c="blue" fw={500}>
              support@runitpostcards.com
            </Text>
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
};

export default TermsPage;
