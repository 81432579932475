// /src/components/Products/ProductCard.tsx
import React from 'react';
import { IconCash, IconEdit, IconTag, IconTrash } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Badge, Card, Group, Image, Modal, Stack, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Product } from '@/interfaces/Product';
import { selectSessionType } from '@/store/selectors/sessionSelectors';

interface ProductCardProps {
  product: Product;
  onEdit: (id: string) => void;
  onDelete: () => void;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, onDelete }) => {
  const navigate = useNavigate();
  const _sessionType = useSelector(selectSessionType);
  const [isImageModalOpen, { open: openImageModal, close: closeImageModal }] = useDisclosure(false);

  return (
    <>
      <Card shadow="sm" padding="md" radius="md" withBorder style={{ maxWidth: 300 }}>
        <Card.Section withBorder inheritPadding py="xs">
          <Group justify="space-between">
            <Stack gap="xs" justify="flex-start">
              <Text size="sm" c="dimmed">
                ID: {product.id}
              </Text>
              <Badge size="xs" bg={product.status === 'published' ? 'green' : 'gray'}>
                {product.status.toUpperCase()}
              </Badge>
              {product.is_admin_product && (
                <Badge size="xs" color="yellow" variant="filled">
                  template
                </Badge>
              )}
            </Stack>
            <Group gap="xs">
              <Tooltip label="Edit product" withArrow>
                <ActionIcon onClick={() => navigate(`/products/edit/${product.id}`)}>
                  <IconEdit size={18} />
                </ActionIcon>
              </Tooltip>
              {!product.is_admin_product && (
                <Tooltip label="Delete product" withArrow>
                  <ActionIcon bg="red" onClick={onDelete}>
                    <IconTrash size={18} />
                  </ActionIcon>
                </Tooltip>
              )}
            </Group>
          </Group>
        </Card.Section>

        <Card.Section mt="xs">
          <Image
            src={product.attributes.frontImageUrl || 'https://placehold.co/400x300'}
            alt={product.name}
            height={160}
            width="100%"
            fit="contain"
            onClick={openImageModal}
            style={{ cursor: 'pointer' }}
          />
        </Card.Section>

        <Group justify="space-between" mt="md" mb="xs">
          <Text fw={500}>{product.name}</Text>
        </Group>

        <Text size="sm" c="dimmed">
          {product.description}
        </Text>

        <Group mt="md" gap="sm">
          <IconCash size={16} />
          <Text>${product.price.toFixed(2)}</Text>
        </Group>

        {product.attributes.size && (
          <Group mt="xs" gap="sm">
            <IconTag size={16} />
            <Text>{product.attributes.size}</Text>
          </Group>
        )}
      </Card>

      <Modal opened={isImageModalOpen} onClose={closeImageModal} padding={0} centered size="lg">
        <Image
          src={product.attributes.frontImageUrl || 'https://placehold.co/800x600'}
          alt={product.name}
          width="100%"
        />
      </Modal>
    </>
  );
};

export default ProductCard;
