import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Anchor, Box, Container, Flex, Group, Title } from '@mantine/core';

const HeaderMenu: React.FC = () => {
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  return (
    <Box
      px="md"
      py="sm"
      style={{
        backgroundColor: 'var(--mantine-color-body)',
        boxShadow: 'var(--mantine-shadow-sm)',
      }}
    >
      <Container size="lg">
        <Flex justify="space-between" align="center">
          <Title order={3}>RunIt Postcards</Title>
          <Group gap="lg">
            <Anchor
              component={Link}
              to="/"
              c={isActive('/') ? 'blue.6' : 'dimmed'}
              fw={500}
              underline="never"
            >
              Home
            </Anchor>
            <Anchor
              component={Link}
              to="/docs"
              c={isActive('/docs') ? 'blue.6' : 'dimmed'}
              fw={500}
              underline="never"
            >
              Documentation
            </Anchor>
          </Group>
        </Flex>
      </Container>
    </Box>
  );
};

export default HeaderMenu;
