import { OrderItem, OrderViewModel } from '@/interfaces/Orders';

export function transformOrder(order: OrderItem): OrderViewModel {
  const req = order.attributes.order_request;

  return {
    productId: order.product_id,
    size: req.size,
    message: req.message,
    recipients: req.recipients,
    returnName: req.return_name,
    returnAddress: req.return_address,
    returnCity: req.return_city,
    returnState: req.return_state,
    returnPostalCode: req.return_postal_code,
    frontImageUrl: req.front_image_url,
    createdAt: order.created_at,
    status: order.status,
    isTestMode: order.attributes.test_mode,
  };
}
