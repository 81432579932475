import { Box, Card, Container, Grid, Text, Title } from '@mantine/core';
import HeaderMenu from '@/components/HeaderMenu/HeaderMenu';

const videoEmbeds = [
  {
    id: '1',
    title: 'Registration',
    embed: (
      <iframe
        allowFullScreen
        src="https://komododecks.com/embed/recordings/FnR9Q9ncsg32tPAD8ERx?onlyRecording=1"
        width="100%"
        height="100%"
        style={{ border: 0, position: 'absolute', inset: 0 }}
        title="Registration"
      />
    ),
  },
  {
    id: '2',
    title: 'How to Create and Edit Postcards',
    embed: (
      <iframe
        allowFullScreen
        src="https://komododecks.com/embed/recordings/YGfmdysqUvqSZzEGcZBU?onlyRecording=1"
        width="100%"
        height="100%"
        style={{ border: 0, position: 'absolute', inset: 0 }}
        title="How to Create and Edit Postcards"
      />
    ),
  },
  {
    id: '3',
    title: 'Sending A Postcard to a Contact',
    embed: (
      <iframe
        allowFullScreen
        src="https://komododecks.com/embed/recordings/cmjO0ubT2Th20lbVIqBu?onlyRecording=1"
        width="100%"
        height="100%"
        style={{ border: 0, position: 'absolute', inset: 0 }}
        title="Sending A Postcard to a Contact"
      />
    ),
  },
  {
    id: '4',
    title: 'Settings Page – Senders and Test Mode',
    embed: (
      <iframe
        allowFullScreen
        src="https://komododecks.com/embed/recordings/4zUf32eaPWjPf7Foicqr?onlyRecording=1"
        width="100%"
        height="100%"
        style={{ border: 0, position: 'absolute', inset: 0 }}
        title="Settings Page - Senders and Test Mode"
      />
    ),
  },
  {
    id: '5',
    title: 'Billing Page Explained',
    embed: (
      <iframe
        allowFullScreen
        src="https://komododecks.com/embed/recordings/JpLdbbLewivhDo47Va7V?onlyRecording=1"
        width="100%"
        height="100%"
        style={{ border: 0, position: 'absolute', inset: 0 }}
        title="Billing Page Explained"
      />
    ),
  },
];

const DocsPage = () => {
  return (
    <Box>
      <HeaderMenu />
      <Box
        style={{
          position: 'relative',
          textAlign: 'center',
          height: '20vh',
          background: 'linear-gradient(135deg, #007bff 0%, #00d4ff 100%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }}
      >
        <Container size="md">
          <Title order={1} size="h1" style={{ fontWeight: 800, textAlign: 'center' }}>
            RunIt Postcards Documentation
          </Title>
          <Text size="lg" c="white" style={{ marginTop: '1rem', textAlign: 'center' }}>
            Learn how to master your direct mail automation.
          </Text>
        </Container>
      </Box>

      <Container size="lg" py="xl">
        <Grid gutter="xl">
          {videoEmbeds.map((video) => (
            <Grid.Col span={{ base: 12, md: 6 }} key={video.id}>
              <Card shadow="md" radius="md" p="md" style={{ overflow: 'hidden' }}>
                <Title order={4} mb="sm">
                  {video.title}
                </Title>
                <Box style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                  {video.embed}
                </Box>
              </Card>
            </Grid.Col>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default DocsPage;
