// /src/Router.tsx
import { Navigate, Route, Routes } from 'react-router-dom';
import { SSOSessionType } from '@/interfaces/SSOSession';
import AgencyBilling from '@/pages/dashboards/agency/AgencyBilling.page';
import AgencyDashboard from '@/pages/dashboards/agency/AgencyDashboard.page';
import AgencyHome from '@/pages/dashboards/agency/AgencyHome.page';
import AgencyProductsPage from '@/pages/dashboards/agency/AgencyProduct.page';
import ReportsPage from '@/pages/dashboards/agency/Reports.page';
import ProductEditPage from '@/pages/dashboards/ProductEdit.page';
import SubAccountBillingPage from '@/pages/dashboards/subaccount/SubAccountBilling.page';
import SubAccountLayout from '@/pages/dashboards/subaccount/SubAccountLayout.page';
import SubaccountProductsPage from '@/pages/dashboards/subaccount/SubaccountProduct.page';
import Home from '@/pages/Home.page';
import RequestStorage from '@/pages/request-storage/RequestStorage.page';
import SSOPage from '@/pages/sso/SSOPage.page';
import UnauthorizedPage from '@/pages/Unauthorized.page';
import AuthGuard from '@/utils/guards/AuthGuard';
import AgencySettingsPage from './pages/dashboards/agency/AgencySettings.page';
import SubAccountDashboardPage from './pages/dashboards/subaccount/SubAccountDashboard.page';
import SubAccountOrdersPage from './pages/dashboards/subaccount/SubAccountOrders.page';
import SubaccountSettingsPage from './pages/dashboards/subaccount/SubAccountSettings.page';
import DocsPage from './pages/Docs.page';
import PrivacyPolicyPage from './pages/PrivacyPolicy.page';
import TermsPage from './pages/TermsAndConditions.page';

const Router = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/docs" element={<DocsPage />} />
    <Route path="/terms" element={<TermsPage />} />
    <Route path="/privacy" element={<PrivacyPolicyPage />} />
    <Route path="/sso" element={<SSOPage />} />
    <Route path="/unauthorized" element={<UnauthorizedPage />} />
    <Route path="/request-storage" element={<RequestStorage />} />

    {/* Parent route for agency dashboard */}
    <Route
      path="/agency-dashboard"
      element={
        <AuthGuard sessionTypes={[SSOSessionType.AGENCY]}>
          <AgencyDashboard />
        </AuthGuard>
      }
    >
      {/* Nested routes */}
      <Route index element={<AgencyHome />} />
      <Route path="reports" element={<ReportsPage />} />
      <Route path="billing" element={<AgencyBilling />} />
      <Route path="products" element={<AgencyProductsPage />} />
      <Route path="settings" element={<AgencySettingsPage />} />
    </Route>

    <Route
      path="/sub-account-dashboard"
      element={
        <AuthGuard sessionTypes={[SSOSessionType.SPOOFED_ACCOUNT, SSOSessionType.ACCOUNT]}>
          <SubAccountLayout />
        </AuthGuard>
      }
    >
      <Route path="dashboard" element={<SubAccountDashboardPage />} />
      <Route path="products" element={<SubaccountProductsPage />} />
      <Route path="billing" element={<SubAccountBillingPage />} />
      <Route path="orders" element={<SubAccountOrdersPage />} />
      <Route path="settings" element={<SubaccountSettingsPage />} />
    </Route>

    <Route
      path="/products/edit/:id?"
      element={
        <AuthGuard
          sessionTypes={[
            SSOSessionType.AGENCY,
            SSOSessionType.SPOOFED_ACCOUNT,
            SSOSessionType.ACCOUNT,
          ]}
        >
          <ProductEditPage />
        </AuthGuard>
      }
    />
    {/* Catch-all route */}
    <Route path="*" element={<Navigate to={import.meta.env.VITE_APP_UNAUTHORIZED} replace />} />
  </Routes>
);

export default Router;
