// /src/components/SearchOrders.tsx
import React, { useEffect, useState } from 'react';
import { IconSearch } from '@tabler/icons-react';
import { ActionIcon, Box, Grid, Group, LoadingOverlay, Stack, TextInput } from '@mantine/core';
import { OrderViewModel } from '@/interfaces/Orders';
import { fetchOrders } from '@/services/subaccountService';
import { transformOrder } from '@/utils/transformOrder';
import OrderCard from './OrderCard';

const SearchOrders: React.FC = () => {
  const [orders, setOrders] = useState<OrderViewModel[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const loadOrders = async (query?: string) => {
    setLoading(true);
    try {
      const response = await fetchOrders();
      const viewModels = response.data.orders.map(transformOrder);
      setOrders(viewModels);
    } catch (error) {
      console.error('Failed to load orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    loadOrders(search.trim());
  };

  useEffect(() => {
    loadOrders(); // Trigger search on first mount
  }, []);

  return (
    <Stack p="md">
      <Group>
        <TextInput
          placeholder="Search by message or return city/name..."
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          style={{ flexGrow: 1 }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <ActionIcon variant="filled" color="blue" onClick={handleSearch}>
          <IconSearch size={18} />
        </ActionIcon>
      </Group>

      {/* Use Box with relative positioning so LoadingOverlay sits correctly */}
      <Box pos="relative" mt="md">
        <LoadingOverlay
          visible={loading}
          zIndex={5}
          overlayProps={{ opacity: 0.25 }}
          loaderProps={{ size: 'md', variant: 'oval', color: 'blue' }}
        />
        <Grid>
          {orders.map((order) => (
            <Grid.Col
              span={{ base: 12, sm: 6, md: 4, lg: 3 }}
              key={order.createdAt + order.frontImageUrl}
            >
              <OrderCard order={order} />
            </Grid.Col>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};

export default SearchOrders;
